import { AuthHelper } from '../../helpers/AuthHelper'
import axios from "axios";

const STORE_ENDPOINT = process.env.REACT_APP_DELIVERYAPI_URL

export async function getStoreDetails(storeNumber: string) : Promise<StoreDetails> {
    const userAuthTokens = await AuthHelper.getUserAuthTokens();
    const config = {
        headers: { Authorization: `Bearer ${userAuthTokens.accessToken}` }
    };

    try {
        const response = await axios.get<StoreDetails>(`${STORE_ENDPOINT}/napaStores/store/${storeNumber}`, config);
        if (response.status === 200) {
            return response.data;
        }
        return Promise.reject(`[${response.status}]: ${response.data}`);
    } catch (e) {
        return {
            storeNumber: storeNumber,
            doordashEnabledFlag: false
        } as StoreDetails
    }
}

export async function getStores (username: string) : Promise<string[]> {
    const userAuthTokens = await AuthHelper.getUserAuthTokens();
    const config = {
        headers: { Authorization: `Bearer ${userAuthTokens.accessToken}` }
    };

    try {
        const response = await axios.get<string[]>(`${STORE_ENDPOINT}/store/username/${username}`, config);
        if (response.status === 200) {
            return response.data;
        }
        return Promise.reject(`[${response.status}]: ${response.data}`);
    } catch (e) {
        return [];
    }
}

export type StoreDetails = {
    "storeNumber": string,
    "addressLine1": string,
    "addressLine2": string ,
    "city": string,
    "state": string,
    "zipCode": string,
    "latitude": number,
    "longitude": number,
    "doordashEnabledFlag": boolean,
    "autoDispatch": boolean
}